"use client";

import clsx from "clsx";
import { useState } from "react";
import type { InputHTMLAttributes, ChangeEvent } from "react";
import { PasswordRequirementProgressDisplay } from "@/components/Input/Password/components/PasswordRequirementProgressDisplay/PasswordRequirementProgressDisplay";
import { useSite } from "@/contexts/site/site.context";
import { DefaultInput } from "../components/DefaultInput/DefaultInput.component";
import { RevealToggleButton } from "./components/RevealToggleButton/RevealToggleButton.component";
import type { PasswordRequirements } from "./password.types";
import { passwordRequirement } from "./password.types";
type PasswordProps = Omit<InputHTMLAttributes<HTMLInputElement>, "placeholder" | "type"> & {
  readonly debouncedMilliseconds?: number;
};
const minimumPasswordLength = 8;
const requirements = [/.*[A-Z].*/u,
// Contains uppercase letter
/.*[a-z].*/u,
// Contains lowercase letter
/.*\d.*/u // Contains number
];
const minimumPasswordRequirementsMet = 3;
const checkPasswordValidity = (password: string): boolean => {
  const initialCheck = password.length >= minimumPasswordLength;
  if (!initialCheck) {
    return false;
  }
  const fulfilledRequirements = requirements.filter(regex => regex.test(password)).length;
  return fulfilledRequirements >= minimumPasswordRequirementsMet;
};
export function Password(defaultInputProps: PasswordProps) {
  const {
    locale
  } = useSite();
  const [isRevealed, setIsRevealed] = useState<boolean>(false);
  const [fullfilledRequirements, setFullfilledRequirements] = useState<ReadonlyArray<PasswordRequirements>>([]);
  const {
    debouncedMilliseconds
  } = defaultInputProps;
  const checkFullfilledRequirements = (password: string) => {
    const newFullfilledRequirements = [...(password.length >= minimumPasswordLength ? [passwordRequirement.MinimumCharacters] : []), ...(/[A-Z]/u.test(password) ? [passwordRequirement.UppercaseLetter] : []), ...(/[a-z]/u.test(password) ? [passwordRequirement.LowercaseLetter] : []), ...(/\d/u.test(password) ? [passwordRequirement.NumberCharacter] : [])];
    setFullfilledRequirements(newFullfilledRequirements);
  };
  const checkValidity = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      onChange
    } = defaultInputProps;
    const newValue = event.target.value;
    event.target.setCustomValidity(checkPasswordValidity(newValue) ? "" : "invalid");
    checkFullfilledRequirements(newValue);
    onChange?.(event);
  };
  return <div data-sentry-component="Password" data-sentry-source-file="Password.component.tsx">
      <DefaultInput
    /* eslint-disable react/jsx-props-no-spreading -- needed so Password input takes the same props as a built-in input */ {...defaultInputProps} data-testid="password"
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- we need the password input to be more responsive
    debounceMilliseconds={debouncedMilliseconds ?? 250} placeholder={{
      da: "Password",
      de: "Passwort",
      en: "Password",
      no: "Passord",
      sv: "Lösenord"
    }[locale]} type={isRevealed ? "text" : "password"} onChange={checkValidity}
    /* eslint-enable react/jsx-props-no-spreading */ data-sentry-element="DefaultInput" data-sentry-source-file="Password.component.tsx">
        <RevealToggleButton className={clsx("invisible absolute top-1/2 -translate-y-1/2 opacity-0", "focus:visible focus:opacity-100 peer-focus:visible peer-focus:opacity-100", "peer-enabled:not-peer-placeholder-shown:visible peer-enabled:not-peer-placeholder-shown:opacity-100", "peer-read-only:peer-enabled:not-peer-placeholder-shown:invisible peer-read-only:peer-enabled:not-peer-placeholder-shown:opacity-0", "right-1 peer-focus:not-peer-placeholder-shown:right-5")} isRevealed={isRevealed} onMouseDown={() => setIsRevealed(!isRevealed)} // Using `onMouseDown` instead of `onClick` prevents the button from stealing focus from the input field.
      data-sentry-element="RevealToggleButton" data-sentry-source-file="Password.component.tsx" />
      </DefaultInput>
      <PasswordRequirementProgressDisplay fullfilledRequirements={fullfilledRequirements} data-sentry-element="PasswordRequirementProgressDisplay" data-sentry-source-file="Password.component.tsx" />
    </div>;
}