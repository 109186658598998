"use client";

import { EmailLink } from "@/components/EmailLink/EmailLink.component";
import { useSite } from "@/contexts/site/site.context";
import type { ErrorMessageStatus } from "@/services/utilities/fetchable";
import { inlineJsx } from "@/ts/locale/inline-jsx";
import type { Locale } from "@/ts/locale/locale.types";
import { Alert } from "../Alert/Alert.component";
type ErrorMessageProps = {
  readonly status: ErrorMessageStatus;
};
export function ErrorMessage({
  status
}: ErrorMessageProps) {
  const {
    locale
  } = useSite();
  return <Alert className="bg-white" data-sentry-element="Alert" data-sentry-component="ErrorMessage" data-sentry-source-file="ErrorMessage.component.tsx">
      {getErrorMessageElementByStatus(status, locale)}
    </Alert>;
}
function getErrorMessageElementByStatus(status: ErrorMessageStatus, locale: Locale): JSX.Element {
  const supportEmailLink = <EmailLink className="text-primary hover:underline" emailAddress={{
    da: "support@infowatch.dk",
    de: "support@finanzbusiness.de",
    en: "support@infowatch.dk",
    no: "support@watchmedia.no",
    sv: "support@watchmedia.se"
  }[locale]} />;
  switch (status) {
    case "PASSWORD_REQUIREMENTS_NOT_MET":
      return <p>
          {{
          da: "Dit indtastede kodeord opfylder ikke kravene. Dit kodeord skal være på mindst 8 tegn og indeholde et stort og et lille bogstav samt et tal.",
          de: "Ihr eingegebenes Passwort erfüllt nicht die Anforderungen. Ihr Passwort muss mindestens 8 Zeichen lang sein und einen Groß- und Kleinbuchstaben sowie eine Zahl enthalten.",
          en: "Your entered password does not meet the requirements. Your password must be at least 8 characters long and contain an uppercase and a lowercase letter as well as a number.",
          no: "Det inntastede passordet ditt oppfyller ikke kravene. Passordet ditt må være på minst 8 tegn og inneholde en stor og en liten bokstav samt et tall.",
          sv: "Ditt angivna lösenord uppfyller inte kraven. Ditt lösenord måste vara minst 8 tecken långt och innehålla en stor och en liten bokstav samt ett nummer."
        }[locale]}
        </p>;
    case "BLACKLISTED":
      return <p>
          {{
          da: inlineJsx`Du kan ikke oprette en bruger med denne e-mailadresse. Kontakt os på ${supportEmailLink}, hvis du har brug for hjælp.`,
          de: inlineJsx`Leider können Sie mit dieser E-Mail-Adresse keinen Benutzer erstellen. Kontaktieren Sie uns unter ${supportEmailLink}, wenn Sie Hilfe benötigen.`,
          en: inlineJsx`You cannot create a user with this email address. Contact us at ${supportEmailLink} if you need assistance.`,
          no: inlineJsx`Du kan ikke opprette en bruker med denne e-postadressen. Kontakt oss på ${supportEmailLink} hvis du trenger hjelp.`,
          sv: inlineJsx`Du kan inte skapa en användare med denna e-postadress. Kontakta oss på ${supportEmailLink} om du behöver hjälp.`
        }[locale]}
        </p>;
    case "TRIAL_ALREADY_USED":
      return <p>
          {{
          da: "Du har allerede benyttet dig af muligheden for et personligt prøveabonnement. Du er velkommen til at kontakte vores salgsteam om et uforpligtende prøveabonnement til din arbejdsplads.",
          de: "Sie haben bereits die Möglichkeit eines persönlichen Probeabonnements genutzt. Gerne können Sie sich bezüglich des Erwerbs Ihres Abonnements oder eines unverbindlichen Unternehmens-Probeabonnement an unser Vertriebsteam wenden.",
          en: "You have already used the option of a personal trial subscription. You are welcome to contact our sales team about a non-binding trial subscription for your workplace.",
          no: "Du har allerede benyttet deg av muligheten for et personlig prøveabonnement. Men du er alltid velkommen til å kontakte vårt salgsteam for å få et uforpliktende prøveabonnement knyttet til din bedrift.",
          sv: "Du har redan utnyttjat möjligheten till en personlig provprenumeration. Du är välkommen att kontakta vårt försäljningsteam om en icke-bindande provprenumeration för din arbetsplats."
        }[locale]}
        </p>;
    case "USER_ALREADY_EXISTS":
      return <p>
          {{
          da: "Du har allerede et login.",
          de: "Sie haben schon ein Login.",
          en: "You already have an account.",
          no: "Du har allerede en brukerkonto.",
          sv: "Du har redan ett konto."
        }[locale]}
        </p>;
    case "NOT_FOUND":
      return <p>
          {{
          da: "Vi kunne ikke finde en bruger med denne e-mailadresse.",
          de: "Wir konnten keinen Benutzer mit dieser E-Mail-Adresse finden.",
          en: "We could not find a user with this email address.",
          no: "Vi kan ikke finne en bruker med denne e-postadressen",
          sv: "Vi kunde inte hitta en användare med denna e-postadress."
        }[locale]}
        </p>;
    default:
      return <p>
          {{
          da: "Der skete en fejl. Prøv venligst igen senere.",
          de: "Ein Fehler ist aufgetreten. Probieren Sie Später nochmal.",
          en: "An error has occurred. Please try again later.",
          no: "Det skjedde en feil. Vennligst prøv igjen senere.",
          sv: "Ett fel har inträffat. Försök igen senare."
        }[locale]}
        </p>;
  }
}