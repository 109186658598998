import type { CommonHeroIconProps } from "./common-hero-icon-props.types";

/**
 * The solid 24x24 'x-mark' icon from [HeroIcons 2.0](https://heroicons.com).
 */
export function SolidCheckMarkIcon({
  className
}: CommonHeroIconProps) {
  return <svg className={className} fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-component="SolidCheckMarkIcon" data-sentry-source-file="SolidCheckMarkIcon.component.tsx">
      <path d="m4.5 12.75 6 6 9-13.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SolidCheckMarkIcon.component.tsx" />
    </svg>;
}