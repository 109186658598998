import type { CommonHeroIconProps } from "./common-hero-icon-props.types";

/**
 * The solid 24x24 'eye' icon from [HeroIcons](https://heroicons.com).
 */
export function SolidEyeIcon({
  className
}: CommonHeroIconProps) {
  return <svg className={className} fill="currentColor" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-component="SolidEyeIcon" data-sentry-source-file="SolidEyeIcon.component.tsx">
      <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" data-sentry-element="path" data-sentry-source-file="SolidEyeIcon.component.tsx" />
      <path clipRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" fillRule="evenodd" data-sentry-element="path" data-sentry-source-file="SolidEyeIcon.component.tsx" />
    </svg>;
}