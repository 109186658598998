import { clsx } from "clsx";
import type { MouseEventHandler } from "react";
import { SolidEyeIcon } from "@/components/Icons/components/HeroIcons/SolidEyeIcon.component";
import { SolidEyeSlashIcon } from "@/components/Icons/components/HeroIcons/SolidEyeSlashIcon.component";
type RevealToggleButtonProps = {
  readonly className?: string;
  readonly isRevealed: boolean;
  readonly onMouseDown?: MouseEventHandler<HTMLButtonElement>;
};
export function RevealToggleButton({
  className,
  isRevealed,
  onMouseDown
}: RevealToggleButtonProps) {
  const IconComponent = isRevealed ? SolidEyeSlashIcon : SolidEyeIcon;
  return <button className={clsx(className, "flex h-2.5 w-2.5 items-center rounded-full bg-dim p-px transition-all hover:bg-nero motion-reduce:transition-none")} tabIndex={-1} // Keyboard accessibility has been omitted for now.
  type="button" onMouseDown={event => {
    event.preventDefault();
    onMouseDown?.(event);
  }} data-sentry-component="RevealToggleButton" data-sentry-source-file="RevealToggleButton.component.tsx">
      <IconComponent className="h-full w-full fill-white" data-sentry-element="IconComponent" data-sentry-source-file="RevealToggleButton.component.tsx" />
    </button>;
}