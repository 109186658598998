import type { CommonHeroIconProps } from "./common-hero-icon-props.types";

/**
 * The solid 24x24 'eye-slash' icon from [HeroIcons](https://heroicons.com).
 */
export function SolidEyeSlashIcon({
  className
}: CommonHeroIconProps) {
  return <svg className={className} fill="currentColor" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-component="SolidEyeSlashIcon" data-sentry-source-file="SolidEyeSlashIcon.component.tsx">
      <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" data-sentry-element="path" data-sentry-source-file="SolidEyeSlashIcon.component.tsx" />
      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" data-sentry-element="path" data-sentry-source-file="SolidEyeSlashIcon.component.tsx" />
      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" data-sentry-element="path" data-sentry-source-file="SolidEyeSlashIcon.component.tsx" />
    </svg>;
}