import { clsx } from "clsx";
import { SolidCheckMarkIcon } from "@/components/Icons/components/HeroIcons/SolidCheckMarkIcon.component";
import { SolidXMarkIcon } from "@/components/Icons/components/HeroIcons/SolidXMarkIcon.component";
import { Translation } from "@/components/Translation/Translation.component";
import type { Translations } from "@/components/Translation/translation.types";
import type { PasswordRequirements } from "../../password.types";
import { passwordRequirement } from "../../password.types";
type PasswordRequirementProgressDisplayProps = {
  readonly fullfilledRequirements: ReadonlyArray<PasswordRequirements>;
};
const passwordRequirements: Record<PasswordRequirements, Translations> = {
  [passwordRequirement.MinimumCharacters]: {
    da: "Mindst 8 tegn",
    de: "Mindestens 8 Zeichen",
    en: "At least 8 characters",
    no: "Minst 8 tegn",
    sv: "Minst 8 tecken"
  },
  [passwordRequirement.UppercaseLetter]: {
    da: "Mindst et stort bogstav",
    de: "Mindestens ein Großbuchstabe",
    en: "At least one uppercase letter",
    no: "Minst en stor bokstav",
    sv: "Minst en stor bokstav"
  },
  [passwordRequirement.LowercaseLetter]: {
    da: "Mindst et lille bogstav",
    de: "Mindestens ein Kleinbuchstabe",
    en: "At least one lowercase letter",
    no: "Minst en liten bokstav",
    sv: "Minst en liten bokstav"
  },
  [passwordRequirement.NumberCharacter]: {
    da: "Mindst et tal",
    de: "Mindestens eine Nummer",
    en: "At least one number",
    no: "Minst et tall",
    sv: "Minst ett nummer"
  }
};
export function PasswordRequirementProgressDisplay({
  fullfilledRequirements
}: PasswordRequirementProgressDisplayProps) {
  return <ul className="list-none" data-sentry-component="PasswordRequirementProgressDisplay" data-sentry-source-file="PasswordRequirementProgressDisplay.tsx">
      {Object.keys(passwordRequirements).map(requirement => {
      const isFullfilled = fullfilledRequirements.includes((requirement as PasswordRequirements));
      const requirementDescription = passwordRequirements[(requirement as PasswordRequirements)];
      return <li className={clsx("flex items-center", isFullfilled ? "text-success-500" : "text-dim")} data-testid={requirement} key={requirement}>
            {isFullfilled ? <SolidCheckMarkIcon className="aspect-square w-2" /> : <SolidXMarkIcon className="aspect-square w-2" />}
            <span className="text-xs">
              <Translation model={requirementDescription} />
            </span>
          </li>;
    })}
    </ul>;
}