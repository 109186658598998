import clsx from "clsx";
import type { ReactNode } from "react";
import { SolidExclamationCircleIcon } from "../Icons/components/HeroIcons/SolidExclamationCircleIcon.component";
type AlertProps = {
  readonly size?: "sm" | "md";
  /** "warning" | "success" | "info" are yet to be implemented. */
  readonly severity?: "error";
  readonly children: ReactNode;
  readonly className?: string;
};
export function Alert({
  children,
  size = "sm",
  severity = "error",
  className
}: AlertProps) {
  return <div className={clsx(className, "flex hyphens-manual break-words border border-silver", size === "sm" && "gap-x-1 p-1 text-sm", size === "md" && "gap-x-2 p-2")} data-sentry-component="Alert" data-sentry-source-file="Alert.component.tsx">
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- ready to be extended */}
      {severity === "error" ? <SolidExclamationCircleIcon className={clsx("aspect-square shrink-0 fill-error-500", size === "sm" && "h-2.5", size === "md" && "h-3")} /> : null}
      {children}
    </div>;
}